<template>
  <div class="children">
    <Banner :bannerInfo="bannerInfo" />
    <div class="info">
      <h2>
        Here at The Bridge we love our children! <br />
        We consider them part of our Church TODAY!
      </h2>
      <p>
        Currently the children stay with us for the first part of the Morning
        service, enjoying sung worship and have the opportunity to use bubbles
        and streamers, we encourage them to sing (and dance!) along with the
        adults. During the sermon slot they have the oppurtunity to go to our
        Sunday club called <strong>Mosaic</strong>. Children who want to can
        join this group, held in the upper room, where there will be opportunity
        to have fun, sing, learn and grow in their faith.
      </p>
      <p>
        On the <strong>first Sunday</strong> of the month, we have Café Style
        Church where we all sit round tables and learn, discuss and often create
        together. This service style has a lovely family feel.
      </p>
      <p>
        We are very keen as a Church to nurture our children and keep them safe
        in Church so we have a robust Safeguarding Policy and Procedures and ask
        all parents / carer’s to fill in a registration form if their child
        wants to join our Sunday Club. Please click on the
        <router-link to="/safeguarding">safeguarding</router-link> button for
        further information.
      </p>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "ChildrenView",
  components: { Banner },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/ChildrenImage.jpg"),
        title1: "CHILDREN AT THE BRIDGE",
        title2: null,
      },
    };
  },
};
</script>

<style scoped>
.children {
  padding-top: 40px;
}

.info {
  width: 65vw;
  margin: 80px auto;
  padding: 0 80px;
}

.info h2 {
  width: fit-content;
  margin: 50px auto;
  padding: 20px 17.5px;
  font-size: 30px;
  letter-spacing: 0.5px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

.info p {
  margin: 20px 0;
  line-height: 27.5px;
}

@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }
}
</style>
