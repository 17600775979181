<template>
  <div class="gallery">
    <img
      id="gallery-image"
      v-for="(image, idx) in images"
      :src="image.image"
      :alt="image.alt"
      :data-caption="image.caption"
      @click="handleImageClick"
    />

    <div id="image-modal-container" class="hidden">
      <button id="close-button" @click="toggleModal">
        <img src="@/assets/close-icon.png" alt="Close button" width="30" />
      </button>
      <img id="modal-image" src="" alt="" />
      <span id="modal-caption"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryView",
  data() {
    return {
      images: [
        {
          image: require("@/assets/gallery-images/baptism_1.webp"),
          caption: "This is what I was born for!",
          alt: "A church member is baptised by two members of the congregation",
        },
        {
          image: require("@/assets/gallery-images/church_1.webp"),
          caption: "Well don't worry, the brides are usually late!",
          alt: "The Bridge Baptist Church decorated for a wedding",
        },
        {
          image: require("@/assets/gallery-images/pa_desk_1.webp"),
          caption: "The technical team with beaming smiles!",
          alt: "Three memebers of the congregation sit around the PA desk in church",
        },
        {
          image: require("@/assets/gallery-images/baptism_2.webp"),
          caption: "It doesn't get any better than this!",
          alt: "A church member is baptised by two members of the congregation",
        },
      ],
    };
  },
  methods: {
    handleImageClick(e) {
      console.log(e.target);
      const modalImage = document.querySelector("#modal-image");
      modalImage.src = e.target.src;
      modalImage.alt = e.target.alt;

      const modalCaption = document.querySelector("#modal-caption");
      modalCaption.textContent = e.target.dataset.caption;

      this.toggleModal();
    },
    toggleModal() {
      const imageModalContainer = document.querySelector(
        "#image-modal-container",
      );

      imageModalContainer.classList.toggle("hidden");
    },
  },
};
</script>

<style scoped>
.gallery {
  padding: 40px;
  text-align: left;
}

#gallery-image {
  height: 250px;
  padding: 20px;
  transition: transform 1s ease;
  cursor: pointer;
}

#image-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  background-color: #000000cc;
  transform: translate(-50%, -50%);
  z-index: 999;
}

#modal-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

#modal-caption {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
}

#close-button {
  position: absolute;
  top: 50px;
  right: 50px;
  background-color: unset;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: transform 0.5s ease;
}

#close-button:hover {
  transform: scale(1.4);
}

.hidden {
  display: none;
}

@media screen and (max-width: 600px) {
  #modal-image {
    width: 70%;
    height: 70%;
  }

  #close-button {
    top: 30px;
    right: 30px;
  }

  #gallery-image {
    height: auto;
    width: 300px;
    padding: 20px 0;
    transition: transform 1s ease;
    cursor: pointer;
  }
}
</style>
