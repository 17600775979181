<template>
  <div class="nav-card-grid">
    <div class="nav-card" :key="cardInfo.id" v-for="cardInfo in this.cardData">
      <NavCard :cardInfo="cardInfo" />
    </div>
  </div>
</template>

<script>
import NavCard from "./NavCard";

export default {
  name: "NavCardGrid",
  components: {
    NavCard,
  },
  data() {
    return {
      cardData: [
        {
          id: 0,
          image: require("@/assets/FestivalImage.jpg"),
          text: "Who we are",
          path: "/whoarewe",
        },
        {
          id: 1,
          image: require("@/assets/WorshipImage.jpg"),
          text: "Our service",
          path: "/ourservice",
        },
        {
          id: 2,
          image: require("@/assets/PrayerImage.jpg"),
          text: "Mid-week group",
          path: "/midweekgroup",
        },
        {
          id: 3,
          image: require("@/assets/TeamImage.jpeg"),
          text: "Meet the team",
          path: "/meettheteam",
        },
        {
          id: 4,
          image: require("@/assets/SocialImage.jpeg"),
          text: "Social",
          path: "/social",
        },
        {
          id: 5,
          image: require("@/assets/ChildrenSquareImage.jpg"),
          text: "Children",
          path: "/children",
        },
        {
          id: 6,
          image: require("@/assets/SafeguardingSquareImage.jpg"),
          text: "Safeguarding",
          path: "/safeguarding",
        },
        {
          id: 7,
          image: require("@/assets/ContactImage.jpeg"),
          text: "Get in touch",
          path: "/getintouch",
        },
      ],
    };
  },
};
</script>

<style scoped>
.nav-card-grid {
  width: 90vw;
  margin: 5vw auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
</style>
