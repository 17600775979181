<template>
  <div class="gallery-container">
    <img :src="images[0]" :style="rotateValues[0]" />
    <img :src="images[1]" :style="rotateValues[1]" />
    <img :src="images[2]" :style="rotateValues[2]" />
  </div>
</template>

<script>
export default {
  name: "Gallery",
  props: {
    images: Array,
    rotateType: Number,
  },
  data() {
    return {
      rotateValues: [
        "transform: rotate(" + -10 * this.rotateType + "deg);",
        "transform: rotate(" + 12 * this.rotateType + "deg);",
        "transform: rotate(" + -8 * this.rotateType + "deg);",
      ],
    };
  },
};
</script>

<style scoped>
.gallery-container {
  width: 35vw;
  padding: 100px 1vw 0;
}

img {
  width: 20vw;
  max-width: 300px;
  max-height: 300px;
  height: 20vw;
  margin: 2vw 10px;
  transform-origin: center;
  border: 2px black solid;
  border-radius: 20px;
}

@media screen and (max-width: 1000px) {
  .gallery-container {
    display: none;
  }
}
</style>
