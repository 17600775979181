<template>
  <div class="meet-the-team">
    <Banner :bannerInfo="bannerInfo" />
    <TeamMemberGrid />
  </div>
</template>

<script>
import TeamMemberGrid from "@/components/MeetTheTeamComponents/TeamMemberGrid";
import Banner from "@/components/Banner.vue";
export default {
  name: "MeetTheTeamView",
  components: {
    TeamMemberGrid,
    Banner,
  },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/TeamImage.jpg"),
        title1: "TEAM",
        title2: null,
      },
    };
  },
};
</script>

<style scoped>
.meet-the-team {
  padding-top: 40px;
}
</style>
