import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WhoAreWeView from "../views/WhoAreWeView";
import OurServiceView from "../views/OurServiceView";
import MidWeekGroupView from "../views/MidWeekGroupView";
import MeetTheTeamView from "../views/MeetTheTeamView";
import SocialView from "../views/SocialView";
import GetInTouchView from "../views/GetInTouchView";
import SafeguardingView from "../views/SafeguardingView";
import MembershipView from "../views/MembershipView";
import ChildrenView from "../views/ChildrenView";
import GalleryView from "../views/GalleryView";

import ConstructionView from "../views/ConstructionView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/whoarewe",
    name: "whoarewe",
    component: WhoAreWeView,
  },
  {
    path: "/ourservice",
    name: "ourservice",
    component: OurServiceView,
  },
  {
    path: "/midweekgroup",
    name: "midweekgroup",
    component: MidWeekGroupView,
  },
  {
    path: "/meettheteam",
    name: "meettheteam",
    component: MeetTheTeamView,
  },
  {
    path: "/social",
    name: "social",
    component: SocialView,
  },
  {
    path: "/getintouch",
    name: "getintouch",
    component: GetInTouchView,
  },
  {
    path: "/safeguarding",
    name: "safeguarding",
    component: SafeguardingView,
  },
  {
    path: "/membership",
    name: "membership",
    component: MembershipView,
  },
  {
    path: "/children",
    name: "children",
    component: ChildrenView,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
