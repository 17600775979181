<template>
  <div class="safeguarding">
    <Banner :bannerInfo="bannerInfo" />
    <div class="info">
      <h2>
        SAFEGUARDING IS THE PROTECTION OF ADULTS AND CHILDREN FROM HARM, ABUSE
        OR NEGLECT
      </h2>
      <p>
        We all have the same rights and expectations to independence, respect,
        choice, fulfilment of our ambitions, to be heard, included, and to have
        privacy and confidentiality. These expectations are central to the way
        in which we interact with each other in our life together.
      </p>
      <p>
        Safeguarding involves a range of activities aimed at promoting the
        individual’s fundamental right to be safe. These include making and
        maintaining safe environments for all, having processes to follow should
        something go wrong, and support for everyone involved.
      </p>
      <p>
        As a member church of the Baptist Union of Great Britain (BUGB) we have
        a safeguarding statement and procedures book which are based on the
        model policy and procedures provided by BUGB (These are available in
        Church).
      </p>
      <p>
        The Leadership Team and others who work with children, young people and
        / or adults at risk all undergo DBS checks (via the Disclosure and
        Barring Service) and also receive appropriate training in safeguarding.
      </p>
      <p>
        As a Church family we seek to keep everyone, who part of The Bridge
        Baptist Church, as safe as possible.
      </p>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "SafeguardingView",
  components: { Banner },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/SafeguardingImage.jpg"),
        title1: "SAFEGUARDING",
        title2: null,
      },
    };
  },
};
</script>

<style scoped>
.safeguarding {
  padding-top: 40px;
}

.info {
  width: 65vw;
  margin: 80px auto;
  padding: 0 80px;
}

.info h2 {
  width: fit-content;
  margin: 50px auto;
  padding: 20px 17.5px;
  font-size: 30px;
  letter-spacing: 0.5px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

.info p {
  margin: 20px 0;
  line-height: 27.5px;
}

@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }
}
</style>
