<template>
  <div class="banner" :style="getBackgroundImage()">
    <div class="title-container">
      <h1>{{ bannerInfo.title1 }}</h1>
      <h1 v-if="bannerInfo.title2 != null">{{ bannerInfo.title2 }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    bannerInfo: Object,
  },
  methods: {
    getBackgroundImage() {
      return { backgroundImage: `url(${this.bannerInfo.image})` };
    },
  },
};
</script>

<style scoped>
.banner {
  max-height: 500px;
  width: 100vw;
  height: 50vw;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.banner .title-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #00000033;
}

.banner .title-container h1 {
  font-size: 50px;
  color: white;
  margin: 10px 0;
}

.banner .title-container h1:nth-child(2) {
  font-size: 25px;
}

@media screen and (max-width: 500px) {
  .banner .title-container h1 {
    font-size: 30px;
  }
  .banner .title-container h1:nth-child(2) {
    font-size: 20px;
  }
}

@media screen and (max-width: 950px) {
  .banner {
    background-attachment: unset;
  }
}
</style>
