<template>
  <div class="who-are-we">
    <Banner :bannerInfo="bannerInfo" />
    <div class="info">
      <p>
        We're a group of ordinary people who have found that following Jesus is
        the best way to live our lives. Daily we want to walk with Jesus and
        work with Him so that we can enjoy our lives, learning how to live
        freely and lightly as He leads us and works in and through us.
      </p>
      <h2>We are a smallish church but we have BIG hearts for Jesus</h2>
      <p>
        We are all at different stages on our journey in life with Jesus – some
        just starting out, others who have been disciples of Jesus for many many
        years. Our Church includes children, families, those who live alone but
        who are part of our Church family, we come from different walks of life
        and from different cultural backgrounds. Recently we have been delighted
        to welcome some people from Hongkong(Chinese: 香港人).
      </p>
      <p>
        We meet regularly on Sunday Mornings at 10.45am for lively contemporary
        worship and we also meet during the week for Bible Study, or to follow a
        short teaching series.
      </p>
      <p>
        Prayer is central to our life together. We believe that pray is simply
        about paying attention to God who is present – we can pray at anytime,
        anywhere. We do gather each month for a prayer event when we take time
        together to pray and share, often using prayer stations to help us pray
        in helpful and creative ways.
      </p>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "AboutView",
  components: { Banner },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/PraiseImage.jpg"),
        title1: "WELCOME",
        title2: null,
      },
    };
  },
};
</script>

<style scoped>
.who-are-we {
  padding-top: 40px;
}

.info {
  width: 65vw;
  margin: 80px auto;
  padding: 0 80px;
}

.info h2 {
  width: fit-content;
  margin: 50px auto;
  padding: 20px 17.5px;
  font-size: 30px;
  letter-spacing: 0.5px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

.info p {
  margin: 20px 0;
  line-height: 27.5px;
}

@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }
}
</style>
