<template>
  <div class="get-in-touch">
    <Banner :bannerInfo="bannerInfo" />
    <div class="info">
      <h2>Get in touch!</h2>
      <h3>You can contact us via either email or phone</h3>
    </div>
    <div class="contact-options">
      <div class="phone-container">
        <h4>PHONE</h4>
        <h5>07580 564995</h5>
      </div>
      <div class="email-container">
        <h4>EMAIL</h4>
        <h5>contactbridgebaptistchurch@gmail.com</h5>
      </div>
    </div>
    <div class="info">
      <h3>Or if you want to swing by!</h3>
      <div class="address-container">
        <h4>ADDRESS</h4>
        <h5>
          The Bridge Baptist Church, Mansfield Rd, Sutton-in-Ashfield, NG17 4FN
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "GetInTouchView",
  components: { Banner },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/PhoneImage.jpg"),
        title1: "CONTACT",
        title2: null,
      },
    };
  },
};
</script>

<style scoped>
.get-in-touch {
  padding-top: 40px;
}

.info {
  width: 65vw;
  margin: 40px auto;
  padding: 0 80px;
}

h2 {
  width: fit-content;
  margin: 50px auto;
  padding: 20px 17.5px;
  font-size: 30px;
  letter-spacing: 0.5px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.contact-options {
  width: 95vw;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

h4 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

h5 {
  font-size: 16px;
  font-weight: 600;
}

.contact-options .phone-container,
.contact-options .email-container {
  width: 40%;
  margin: 30px auto;
  padding: 40px 10px;
  background: black;
  color: white;
  border-radius: 30px;
}

.info .address-container {
  margin: 30px 0;
  padding: 40px 10px;
  background: black;
  color: white;
  border-radius: 30px;
}

@media screen and (max-width: 760px) {
  .info {
    padding: 0 20px;
  }

  .contact-options {
    width: 100vw;
    flex-direction: column;
  }

  .contact-options .phone-container,
  .contact-options .email-container {
    min-width: 60%;
  }

  h4 {
    font-size: 25px;
  }

  h5 {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .contact-options .phone-container,
  .contact-options .email-container {
    width: auto;
  }
  h5 {
    font-size: 14px;
  }
}
</style>
