<template>
  <div class="social">
    <Banner :bannerInfo="bannerInfo" />
    <div class="page-content">
      <Gallery
        :images="[
          require('@/assets/CoffeeSocial.jpg'),
          require('@/assets/ChristmasSocial.jpg'),
          require('@/assets/JubileeSocial.jpg'),
        ]"
        :rotateType="-1"
      />
      <div class="info">
        <p>
          As a Church community we enjoy meeting for social events throughout
          the year – these events give us the opportunity just to
          <em>'be together’</em>.
        </p>
        <h2>Hong Kong Social</h2>
        <p>We sampled snacks together made by our Hong Kong friends.</p>
        <h2>Costa Catchup</h2>
        <p>
          After a Churches Together service in Sutton town centre we met up in
          Costa for coffee and a catch up.
        </p>
        <h2>Maundy Thursday</h2>
        <p>Maundy Thursday supper and reflection.</p>
        <h2>Welcome Course Afternoon Tea</h2>
        <p>
          We had an afternoon Tea as part of our ‘Welcome Course’- helping our
          friends from Hong Kong settle into life in the UK
        </p>
      </div>
      <Gallery
        :images="[
          require('@/assets/Jubilee2Social.jpg'),
          require('@/assets/SchoolSocial.jpg'),
          require('@/assets/Jubilee3Social.jpg'),
        ]"
        :rotateType="1"
      />
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Gallery from "@/components/Gallery.vue";
export default {
  name: "SocialView",
  components: { Banner, Gallery },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/HandsImage.jpg"),
        title1: "Social",
        title2: null,
      },
    };
  },
};
</script>

<style scoped>
.social {
  padding: 40px 0 0;
}

.page-content {
  width: 100%;
  display: flex;
}

.info {
  width: 30vw;
  margin: 80px auto;
  /* padding: 0 80px; */
}

.info h2 {
  width: fit-content;
  margin: 50px auto 30px;
  padding: 20px 17.5px;
  font-size: 25px;
  letter-spacing: 0.5px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

.info p {
  margin: 20px 0;
  line-height: 27.5px;
}

@media screen and (max-width: 1000px) {
  .info {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .info {
    width: 80vw;
    padding: 0 20px;
  }
}
</style>
