<template>
  <div class="membership">
    <Banner :bannerInfo="bannerInfo" />
    <div class="info">
      <h2>Church Membership at The Bridge</h2>
      <div class="title-container">
        <h2>Loving God</h2>
        <h2>Loving each other</h2>
      </div>
      <p>
        At the heart of The Bridge Baptist Church is a desire for God to build
        us into a healthy faith community where we can all encounter God.
      </p>
      <p>
        When someone becomes a follower of Jesus Christ, commits their life to
        His Lordship and affirms this in Baptism then we recognise that they
        become a member of God’s worldwide Church, joining with millions of
        other Christians as part of God’s great family on earth.
      </p>
      <p>
        As well as joining God’s worldwide Church we believe it is also helpful
        and healthy for each Christian to find a local group of believers who
        they can connect with, a local church where they can be encouraged in
        their faith, develop their gifts, given to them by God, and serve within
        and beyond the church, partnering with God as He brings His kingdom on
        earth.
      </p>
      <p>
        At the Bridge Baptist Church we practice a Covenantal form of
        membership, which we renew each year in a service of celebration and
        commitment.
      </p>
      <p>
        This kind of membership is all about relationships – our relationship
        with God and with each other. In its simplest form it is about walking
        well together and watching over each other as we all follow Jesus.
      </p>
      <p>
        If you would like to find out more about being part of the Church at The
        Bridge Baptist Church then please speak to one of the leadership Team.
      </p>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "MembershipView",
  components: { Banner },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/HeartHandsImage.jpeg"),
        title1: "MEMBERSHIP",
        title2: null,
      },
    };
  },
};
</script>

<style scoped>
.membership {
  padding-top: 40px;
}

.info {
  width: 65vw;
  margin: 80px auto;
  padding: 0 80px;
}

.info .title-container {
  width: fit-content;
  margin: 50px auto;
  padding: 20px 17.5px;
  font-size: 30px;
  letter-spacing: 0.5px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

.info h2 {
  width: fit-content;
  margin: 0 auto;
  padding: 0;
  font-size: 30px;
}

.info p {
  margin: 20px 0;
  line-height: 27.5px;
}

@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }
}
</style>
