<template>
    <div class="construction">
        <div class="banner">
            <div class="banner-text">
                <h2>THIS PAGE IS CURRENTLY UNDER CONSTRUCTION</h2> 
                <!-- <h3>Join us on Sundays at 10:45</h3> -->
            </div>
            <div class="banner-image-container">
                <img class='banner-image' src="@/assets/Under-Construction.png" alt="Construction Image">
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .construction {
        min-height: 60vh;
        padding: 40px 0 0;
    }

    .banner {
        display: block;
    }

    .banner-text {
        margin: 0 auto;
        padding-bottom: 20px;
        max-width: 400px;
    }

    .banner-text h2 {
        padding-bottom: 30px;
    }

    /* .banner-text h3 {

        background: black;
        color: white;
        padding: 8px 0;
        margin: 0 20px;
    */

    .banner-image {
        width: 300px;
        margin-bottom: 20px;
    }

    @media screen and (min-width: 600px) {
        .banner {
            display: flex;
            align-items: center;
            width: 90vw;
            padding: 0 5vw;
        }

        .banner-text {
            width: 50%;
            font-size: 2.5vw;
            max-width: none;
        }

        .banner-image-container {
            width: 50%;
        }

        .banner-image {
            width: 40vw;
        }
    }
</style>