<template>
    <div class="team-member-card">
        <div class="info-container">
            <div class="image" :style="{ backgroundImage: 'url(' + this.person.image + ')' }"/>
            <h2>{{this.person.name}}</h2>
            <h3>{{this.person.role}}</h3>
        </div>
        <div class="quote-container">
            <div class="quote">
                <p>{{this.person.p1}}</p>
                <p>{{this.person.p2}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamMemberCard',
    props: {
        person: Object
    }
}
</script>

<style scoped>
.team-member-card {
    width: 40vw;
    height: 20vw;
    margin-bottom: 4vw;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
    display: flex;
}
.info-container {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.quote-container {
    width: 60%;
    height: 100%;
}

.image {
    width: 10vw;
    height: 10vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    /*border: 1px dashed black;*/
}

.info-container h2 {
    font-size: 1.7vw;
}
.info-container h3 {
    font-size: 1.4vw;
}

.quote {
    padding: 1vw 1vw;
    display: flex;
    flex-direction: column;
}

.quote p {
    font-size: 1vw;
    text-align: left;
    margin-top: 1vw;
}

@media screen and (max-width: 1000px) {
    .team-member-card {
        width: 80vw;
        height: 40vw;
        margin-bottom: 8vw;
        border-radius: 15px;
        box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
        display: flex;
    }
    .info-container {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .quote-container {
        width: 60%;
        height: 100%;
    }

    .image {
        width: 20vw;
        height: 20vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        /*border: 1px dashed black;*/
    }

    .info-container h2 {
        font-size: 3.5vw;
    }
    .info-container h3 {
        font-size: 3vw;
    }

    .quote {
        padding: 2vw 2vw;
        display: flex;
        flex-direction: column;   
    }

    .quote p {
        font-size: 2vw;
        text-align: left;
        margin-top: 2vw;
    }
}
</style>