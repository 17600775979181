<template>
  <div class="mid-week-group">
    <Banner :bannerInfo="bannerInfo" />
    <div class="info">
      <h2>What?</h2>
      <p>Connect is our mid-week meeting.</p>
      <p>
        We meet to connect with God and each other through study, fellowship and
        prayer.
      </p>
      <h2>When?</h2>
      <p>Tuesdays every other week at <strong>10am</strong></p>
      <p>Upcoming dates:</p>
      <p class="dates">TUESDAY 8th OCTOBER</p>
      <p class="dates">TUESDAY 22nd OCTOBER</p>
      <p class="dates">TUESDAY 5th NOVEMBER</p>
      <p class="dates">TUESDAY 19th NOVEMBER</p>
      <h2>Where?</h2>
      <p>We meet at <strong>The Bridge Baptist Church</strong></p>
      <h2>We would love to see you there!</h2>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "MidWeekGroupView",
  components: { Banner },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/BibleImage.jpg"),
        title1: "CONNECT",
        title2: "STUDY - FELLOWSHIP - PRAYER",
      },
    };
  },
};
</script>

<style scoped>
.mid-week-group {
  padding-top: 40px;
}

.info {
  width: 65vw;
  margin: 80px auto;
  padding: 0 80px;
}

.info h2 {
  width: fit-content;
  margin: 50px auto;
  padding: 10px 30px;
  font-size: 30px;
  letter-spacing: 0.5px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

.info p {
  margin: 15px 0;
  line-height: 27.5px;
}

.info .dates {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}

@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }
}
</style>
