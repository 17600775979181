<template>
  <div class="footer">
    <div class="links">
      <div class="link-container">
        <router-link to="/getintouch">CONTACT</router-link>
      </div>
      <div class="link-container">
        <router-link to="/safeguarding">SAFEGUARDING</router-link>
      </div>
      <div class="link-container">
        <router-link to="/membership">MEMBERSHIP</router-link>
      </div>
      <div class="link-container">
        <router-link to="/gallery">GALLERY</router-link>
      </div>
    </div>
    <div class="info-container">
      <div class="address">
        <h1>FIND US</h1>
        <p>The Bridge Baptist Church</p>
        <p>Mansfield Rd</p>
        <p>Sutton-in-Ashfield</p>
        <p>NG17 4FN</p>
      </div>
      <div class="contacts">
        <h1>CONTACT US</h1>
        <p>0758 056 4995</p>
        <p>contactbridgebaptistchurch@gmail.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  width: 100%;
  background: black;
  color: white;
  padding: 10px;
  letter-spacing: 1.8px;
}

.links {
  margin: 50px 15px 0;
}

.link-container {
  display: inline-block;
  margin: 5px 2.5vw;
}

.info-container {
  margin: 50px auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 700px) {
  .info-container {
    flex-direction: column;
  }

  .address,
  .contacts {
    text-align: left;
    margin: 15px;
  }
}

.address {
  text-align: left;
}

.contacts p {
  padding: 5px 0;
}

a {
  color: white;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  opacity: 0.7;
}
</style>
