<template>
    <div class="team-member-grid">
        <div class="team-member" :key='person.id' v-for='person in this.people'>
            <TeamMemberCard :person='person' />

        </div>
    </div>
</template>

<script>
import TeamMemberCard from './TeamMemberCard'
export default {
    name: 'TeamMemberGrid',
    components: {
        TeamMemberCard
    },
    data() {
        return {
            people: [
                {
                    id: 0,
                    image: require('@/assets/BrianTomlinsonImage.jpeg'),
                    name: 'Brian Tomlinson',
                    role: 'Elder/Secretary',
                    p1: "Brian has been a member of The Bridge for many years; his role encompasses both Elder & Secretary.",
                    p2: "His background has been in engineering and managerial in a local manufacturing company, amongst his many secular interests are walking in Derbyshire, Computers, Reading and Holidaying with his wife and family."
                },
                {
                    id: 1,
                    image: require('@/assets/DerekGardnerImage.jpeg'),
                    name: 'Derek Gardner',
                    role: 'Deacon / Support Liaison',
                    p1: "Derek was originally bought up in a Baptist Church in Kent over some 40 years ago, he then moved to an A.O.G. Church in Huthwaite  and has been a valued member of our Church for the last 12 years.",
                    p2: "His background has been in can making in a local factory and his interests include, his family welfare, Football as well as all sports and walking. Derek loves organising quizzes, if pies are included – so much the better."
                },
                {
                    id: 2,
                    image: require('@/assets/SueGardnerImage.jpeg'),
                    name: 'Sue Gardner',
                    role: 'Deacon / Pastoral Care',
                    p1: "Sue with her husband Derek joined our fellowship some 12 years ago, recently Sue was added to our leadership team here as a Deacon.",
                    p2: "Sue’s main interests are travelling, reading, sharing time with her family and grandchild, Whoops mustn’t forget about her cooking and her scrumptious cakes."
                },
                {
                    id: 3,
                    image: require('@/assets/ElaineBurrowsImage.jpeg'),
                    name: 'Rev. Elaine Burrows',
                    role: 'Community and Service Lead',
                    p1: "Elaine joined our Church in 2019, along with her husband David. She enjoys leading Sunday Services and Preaching, and supports us as we connect with our local community. She enjoys helping others connect with Jesus through prayer.",
                    p2: "Elaine is an accredited Baptist Minister, currently self employed, working in training and mediation.  She also works as a volunteer for the Alzheimer’s Society as a Dementia Ambassador and volunteers at Kings Mill Hospital."
                }

            ]
        }
    }
}
</script>

<style scoped>
.team-member-grid {
    width: 90vw;
    margin: 5vw auto 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media screen and (max-width: 1000px) {
    .team-member-grid {
        flex-direction: column;
        align-items: center;
    }
}
</style>