<template>
  <Header class='desktop'/>
  <div class="buffer-fix"/>
  <div class="content">
    <router-view/>
    <Footer/>
  </div>
</template>


<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    Footer
  }
}
</script>


<style>
#app {
  font-family: 'Source Sans 3', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  width: 100%;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

.buffer-fix {
  width: 100vw;
  height: 150px;
  z-index: -1;
}

@media screen and (max-height:500px) {
  .buffer-fix {
    display: none;
  }
}

.content {
  width: 100vw;
  position: relative;
}
</style>
